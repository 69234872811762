<template>
  <div class="is-loading" v-if="isFetchingData">
    <b-spinner style="border-width: 2px" />
  </div>
  <div v-else>
    <template v-if="dateType == 'limited'">
      <div class="dynamic-select">
        <b-form-select
          v-model="datetime"
          @input="handleInput"
        >
          <b-form-select-option :value="null">{{ placeholder }}</b-form-select-option>
          <b-form-select-option
            v-for="timeOption in dateOptions"
            :key="timeOption.text"
            :value="timeOption.value"
          >
            {{ timeOption.text }}
          </b-form-select-option>
        </b-form-select>
      </div>
    </template>
    <div v-else>
      <datepicker
        :placeholder="placeholder"
        v-model="datetime"
        bootstrap-styling
        format="yyyy/MM/dd"
        :language="zh"
        class="app-datetime-picker"
        :class="{ 'app-datetime-picker--is-invalid': hasError }"
        popover-align="center"
        @input="handleInput"
        v-bind="$attrs"
      >
      </datepicker>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";

export default {
  props: {
    value: [Date, String],
    toFirstSecond: {
      type: Boolean,
      default: false,
    },
    toLastSecond: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    dateOptions: {
      type: Array,
      default: () => [],
    },
    dateType: {
      type: String,
      default: "unlimited",
    },
    isFetchingData: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Datepicker,
  },
  data: () => ({
    zh,
    datetime: null,
  }),
  watch: {
    value() {
      this.datetime = this.value
    },
  },
  mounted() {
    this.checkForProperConfiguration()

    this.$watch(() => `${this.toFirstSecond}${this.toLastSecond}`, this.checkForProperConfiguration)

    this.datetime = this.value
  },
  methods: {
    checkForProperConfiguration() {
      if (this.toFirstSecond === true && this.toLastSecond === true) {
        console.warn('[AppDatetimePicker] Do not set both "toFirstSecond" and "toLastSecond" to be true.')
      }
    },
    handleInput() {
      if (this.dateStyle == 'limited') {
        this.$emit("input", this.datetime);
        return
      }

      if (this.toFirstSecond) {
        this.$emit("input", moment(this.datetime).startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        return
      }

      if (this.toLastSecond) {
        this.$emit("input", moment(this.datetime).endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        return
      }

      this.$emit("input", moment(this.datetime).format("YYYY-MM-DD"));
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors.scss";

.app-datetime-picker {
  padding: 0;
}

::v-deep.app-datetime-picker {
  input {
    border: 1px solid #cdd6dc;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: inherit;
    color: var(--liff-primary_text_color);

    &:focus {
      border: 1px solid var(--liff-primary_text_color, #cdd6dc);
    }
  }

  .vdp-datepicker__calendar {
    max-width: 300px;
  }

  &--is-invalid input {
    border-color: map-get(base-colors.$theme-colors, danger);
  }
}

.dynamic-select {
  position: relative;
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: 600;
  width: 100%;

  select {
    padding: 0px 1rem;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 16px;
    border: dotted 1px var(--liff-button-color) !important;
    border-radius: 3px;

    .dynamic-select--border & {
      border: 1px solid #636366;
      border-radius: 10px;
      padding: 16px 12px;
      &:focus {
        border-bottom-color: #636366;
      }
    }
  }

  &__caret {
    position: absolute;
    right: 20px;
    width: 12px;
    bottom: 30%;
  }

  &--border {
    select {
      border: 1px solid #636366;
      border-radius: 10px;
      padding: 16px 12px;
      &:focus {
        border-bottom-color: #636366;
      }
    }
  }

  &.invalid {
      border: 1px solid #fe0000;
    }
}

.is-loading {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: unset;
}
</style>
